<template>
  <div>
    <v-card outlined class="mx-auto">
      <v-card-title class="primary white--text">
        <div class="headerClass">
          {{ lineSelected }}
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="page !== 'interaction'" :disabled="unremovable" icon @click="closeLine()" class="mx-3 mt-2">
          <v-icon :style="disabledBtnStyle">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete dense outlined required label="Linha" :readonly="isReadonlyByPage" v-model="value.id" :items="lines" item-text="nr_linha"
              item-value="id_linha" :rules="rules" :filter="filterLine" return-object @input="update($event)">
              <template v-slot:selection="data">
                {{ data.item.ddi }} ({{ data.item.ddd }})
                {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
              </template>

              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.ddi }} ({{ data.item.ddd }})
                      {{ data.item.nr_linha }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.nome_completo"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

            <v-text-field
                v-if="desactiveComponentNewIccid"
                :disabled="value.id == null || loading"
                label="ICCID novo SIM Card"
                outlined
                dense
                v-model="customIccid"
                @input="updateValues()"
            >
            </v-text-field>

            <v-text-field
                v-if="page === 'validate' && customIccid"
                :readonly="true"
                label="ICCID solicitado"
                outlined
                dense
                v-model="customIccid"
            >
            </v-text-field>

            <v-combobox v-if="!desactiveComponentNewIccid" :disabled="value.id == null || loading" label="Buscar ou criar novo ICCID" v-model="selectedChip"
              item-text="iccid" item-value="iccid" :readonly="isReadonlyByPage" :rules="rulesIccid" :items="iccids" outlined dense return-object
              :search-input.sync="search" :loading="loading" :filter="filterIccid" @keydown="onKeyDown($event)">
              <template #no-data>
                <v-list-item v-if="search != null && search.length > 0" @click="createIccid = search">
                  <span class="subheading mr-2">Criar ICCID</span>{{ search }}
                </v-list-item>
              </template>

              <template #selection="data">
                <template v-if="selectedChip != null && Object.keys(selectedChip).length > 0 && data.item.ddd">
                  (DDD - {{ data.item.ddd }}) {{ data.item.iccid }}
                </template>
                <template v-if="selectedChip != null && Object.keys(selectedChip).length > 0 && !data.item.ddd">
                  (HIBRIDO) {{ data.item.iccid }}
                </template>
              </template>

              <template #item="data">
                <v-list-item-content v-if="data.item.ddd">
                  <v-list-item-title>(DDD - {{ data.item.ddd }}) {{ data.item.iccid }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="!data.item.ddd">
                  <v-list-item-title>(HIBRIDO) {{ data.item.iccid }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <custom-new-single-chip-form-component v-if="!desactiveComponentNewIccid" v-model="isChipDialogOpen" :iccid="createIccid" :listIccid="iccids" @changeSelectedChip="updateSelectedChip($event)"
      @close="onCloseDialog($event)" />
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import axios from "@/axios.config";
import objectOrNull from '@/helpers/objectOrNull.helper'

import CustomNewSingleChipFormComponent from './CustomNewSingleChipFormComponent'

export default {
  components: { CustomNewSingleChipFormComponent },
  props: {
    value: { default: {} },
    index: {
      type: Number,
      required: true,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    unremovable: {
      type: Boolean,
      default: false,
    },
    selectedNewChip: {
      type: Object,
    },
    listIccid: {
      type: Array,
      default: () => [],
    },
    page: {
      type: String,
      default: ""
    },
    desactiveComponentNewIccid: {
      page: {
        Type: Boolean,
        default: false
      }
    },
    resultStorage: {
      type: Array,
      default: () => [],
    }
  },

  created() { },

  async mounted() {
    if (this.resultStorage[this.index]?.customIccid) {
      this.setLineSelectedTitle(this.resultStorage[this.index].id);
      this.customIccid = this.resultStorage[this.index].customIccid;
    }

    if (this.value != null) {
      this.line = {
        id_linha: this.value.id,
        fields: this.value.fields
      };
      this.setLineSelectedTitle(this.value.id);
    }

    if (this.value?._meta?.line != null) {
      this.line = this.value._meta.line
      if (this.value._meta.line.ddd != null) {
        this.fetchChips(this.value._meta.line.ddd);
      }
    }

    if (this.page === 'validate' || this.page === 'interaction') {
      this.iccids = this.listIccid;
    }

    if (this.page === 'validate') {
      this.customIccid = this.value?.customIccid ?? "";
      this.rulesIccid = [v => !!v || "Iccid é obrigatório"];
    }
  },

  data() {
    return {
      /** Controla estado de loading do combobox ICCID */
      loading: false,

      /** Guarda valor do campo de busca do ICCID */
      search: '',

      /**
       * Guarda os valores válidos da pesquisa de ICCID
       */
      iccids: [],

      /**
       * Guarda linha selecionada para futura interações
       */
      line: {},

      /**
       * Guarda chip selecionado para futura interações
       */
      chip: null,

      rules: [v => !!v || "Linha é obrigatório"],

      rulesIccid: [],

      /* Exibe dialog para inserção de novo chip */
      createIccid: '',

      customIccid: [],
      lineSelected: "Selecione uma linha",
    }
  },

  computed: {
    disabledBtnStyle: function () {
      return this.unremovable ? "color: transparent !important" : undefined;
    },

    isReadonlyByPage() {
      return this.page === 'interaction';
    },

    selectedChip: {
      get: function () {
        return this.value?._meta?.iccid ?? this.chip ?? undefined
      },

      set: function (state) {
        const chip = objectOrNull(state)
        if (typeof state === 'object' && chip != null) {
          this.createIccid = undefined;
          this.emitChange(chip)
        } else {
          /* Novo chip sendo criado.Item é uma string */
          this.createIccid = state
          this.emitChange(null)
        }
      }
    },

    isChipDialogOpen: function () {
      return !isEmpty(this.createIccid)
    }
  },

  methods: {
    setLineSelectedTitle(id) {

      const selectedItem = this.lines.find(item => item.id_linha === id);
      if (selectedItem) {
        this.lineSelected = `${selectedItem.ddi} (${selectedItem.ddd}) ${selectedItem.nr_linha}`;
      }
    },

    filterLine: function (item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },

    filterIccid: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.iccid)
    },

    closeLine: function () {
      this.$emit('remove', this.index)
    },

    updateSelectedChip: function (new_chip) {
      this.selectedChip = new_chip;
    },

    updateValues: function () {
      this.value.customIccid = this.customIccid;
    },

    update: function (selectedItem) {
      this.line = selectedItem;
      this.lineSelected = selectedItem.ddi + ' (' + selectedItem.ddd + ') ' + selectedItem.nr_linha;
      this.emitChange(objectOrNull(this.chip))
    },

    emitChange: function (chip = undefined) {
      this.chip = chip

      const newItem = {
        id: this.line.id_linha,
        fields: [
          {
            name: "iccid",
            type: "iccid",
            label: "ICCID novo SIM Card",
            value: chip?.iccid ?? ""
          }
        ],
        /* Valor para ser recuperado quando salva no local storage */
        _meta: {
          line: this.line,
          iccid: chip,
        }
      }

      this.$emit('input', newItem)
    },

    fetchChips: function (ddd) {
      if (this.loading) return

      this.loading = true

      axios.get('solicitation/chips/available', {
        params: { ddd }
      })
        .then((res) => {
          if (res.data.success) {
            this.iccids = res.data.data
          }
        })
        .finally(() => { this.loading = false })
    },

    onCloseDialog: function (...args) {
      this.createIccid = undefined
      this.search = undefined
    },

    onKeyDown: function (e) {
      /**
       * Workaround: Quando enter é pressionado componente combobox
       * joga valor do search para um campo mágico e acaba com todo o fluxo.
       * Dessa forma esvazia de forma forçada o campo de busca e povoa o campo
       * para criação de iccid
       */
      if (e.keyCode === 13 /* ENTER */) {
        this.createIccid = this.search
        this.search = undefined
      }
    }
  },

  watch: {
    line: {
      deep: true,
      handler: function (newLine, oldLine) {
        if (newLine != null && newLine.id_linha != oldLine?.id_linha) {
          this.fetchChips(newLine.ddd)
        }
      }
    }
  }
}
</script>

<style scoped>
.disabledBtn {
  color: transparent !important;
}
</style>